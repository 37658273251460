import React from 'react';
import clsx from 'clsx';
import { Slot } from '@radix-ui/react-slot';
import { isElement } from 'react-is';
import { createDynamicVars } from '../../global/utils';
import { AsChild, InternalComponentProps } from '../Slot';
import { ResponsiveValue } from '../../global/breakpoints';
import * as boxStyles from './Box.css';

export type SizeParams = `${number}px` | `${number}%` | `${number}vw` | 'auto';

export type BoxProps = {
  id?: string;
  children?: React.ReactNode;
  width?: ResponsiveValue<SizeParams | 'auto'>;
  height?: ResponsiveValue<SizeParams | 'auto'>;
  minHeight?: ResponsiveValue<SizeParams | 'auto'>;
  top?: ResponsiveValue<SizeParams>;
  left?: ResponsiveValue<SizeParams>;
  right?: ResponsiveValue<SizeParams>;
  bottom?: ResponsiveValue<SizeParams>;
  maxWidth?: ResponsiveValue<SizeParams | 'auto'>;
} & boxStyles.Variants;

type BoxComponentProps = BoxProps & AsChild & InternalComponentProps;

export const Box = React.forwardRef<HTMLDivElement, BoxComponentProps>((props, ref) => {
  const {
    id,
    children,
    width,
    height,
    minHeight,
    top,
    left,
    right,
    bottom,
    className = '',
    style = {},
    as = 'div',
    asChild,
    ...recipeProps
  } = props;

  const dynamicVariants = createDynamicVars([
    {
      value: width,
      vars: Object.values(boxStyles.widthVars.extractedVars),
    },
    {
      value: height,
      vars: Object.values(boxStyles.heightVars.extractedVars),
    },
    {
      value: minHeight,
      vars: Object.values(boxStyles.minHeightVars.extractedVars),
    },
    {
      value: top,
      vars: Object.values(boxStyles.topVars.extractedVars),
    },
    {
      value: left,
      vars: Object.values(boxStyles.leftVars.extractedVars),
    },
    {
      value: right,
      vars: Object.values(boxStyles.rightVars.extractedVars),
    },
    {
      value: bottom,
      vars: Object.values(boxStyles.bottomVars.extractedVars),
    },
  ]);

  const recipeClassnames = boxStyles.box(recipeProps);
  const Component = asChild && isElement(children) ? Slot : as;

  return (
    <Component
      id={id}
      className={clsx(
        {
          [boxStyles.width]: !!width,
          [boxStyles.height]: !!height,
          [boxStyles.minHeight]: !!minHeight,
          [boxStyles.top]: !!top,
          [boxStyles.left]: !!left,
          [boxStyles.right]: !!right,
          [boxStyles.bottom]: !!bottom,
        },
        recipeClassnames,
        className,
        boxStyles.root
      )}
      style={{ ...dynamicVariants, ...style }}
      ref={ref}
    >
      {children}
    </Component>
  );
});

